//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchContractType, fetchCauseStep } from "@/api/master";
import {fetchCheckDocumentDetail, saveData} from "@/api/checkDocument";
import { Message } from "element-ui";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  name: "CheckDocumentDetail",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("CheckDocument");
    });
  },
  components: {},
  data() {
    var validateCause = (rule, value, callback) => {
      if (this.formData.statusId === 1 && value.length === 0) {
        callback(new Error('กรุณาเลือกสาเหตุ'));
      } else {
        callback();
      }
    };
    var validateImage = (rule, value, callback) => {
      if (this.formData.statusId === 2 && this.formData.images.length === 0 && this.temp.attachments.length === 0) {
        callback(new Error('กรุณาเพิ่มรูปภาพ'));
      } else {
        callback();
      }
    };
    return {
      validate: false,
      items: "",
      loanType: [],
      status: [
        { value: 0, label: "รอตรวจสอบ" },
        { value: 2, label: "เรียบร้อย" },
        { value: 1, label: "ไม่เรียบร้อย" },
      ],
      causeOption: [],
      isLoading: false,
      dialogImageUrl: "",
      dialogVisible: false,
      temp: {
        attachments: [],
      },
      userParam: {
        contractId: this.$route.params.id,
      },
      balanceFommat: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      causeParam: {
        stepId: 1,
      },
      formData: {
        contractId: "",
        contractTypeId: "",
        statusId: null,
        remark: "",
        stepdCauseId: "",
        stepdImageId: "",
        cause: "",
        images: [],
        imagesDel: [],
      },
      formRules: {
        contractTypeId: [
          { required: true, message: 'กรุณาเลือกประเภทสินเชื่อ', trigger: 'change' }
        ],
        statusId: [
          { required: true, message: 'กรุณาเลือกสถานะของสัญญา', trigger: 'change' }
        ],
        cause: [
          { validator: validateCause, trigger: 'change' }
        ],
        attachments: [
          { validator: validateImage, trigger: 'blur' }
        ],
      },
    };
  },

  async mounted() {
    this.$store.state.loading = true;
    try {
      await new Promise((resolve, reject) => {
        fetchContractType()
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.loanType = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });

        fetchCauseStep(this.causeParam)
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.causeOption = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });
      });
      await this.getDataDetail();
    } finally {
      this.$store.state.loading = false;
    }
  },

  methods: {
    getDataDetail() {
      fetchCheckDocumentDetail(this.userParam)
        .then((res) => {
          this.items = [];
          const check = res.header;
          const data = res.body;
          if (check.error == "N") {
            data[0].start_date_contract = new Date(
              data[0].start_date_contract
            ).toLocaleDateString("en-GB");
            data[0].balance = Number(data[0].balance).toLocaleString(
              "en",
              this.balanceFommat
            );
            this.formData.contractId = data[0].contract_id;
            this.formData.contractTypeId = data[0].contract_type_id;
            this.formData.statusId = data[0].status_id;
            this.formData.cause = data[0].cause;
            this.formData.remark = data[0].remark;
            this.formData.stepdCauseId = data[0].stepd_cause_id;
            this.formData.stepdImageId = data[0].stepd_image_id;
            this.items = data[0];
            this.temp.attachments = data[0].images;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log("error :", error);
        });
    },
    saveData() {
      console.log('formData --> ', this.formData);
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          saveData(this.formData)
              .then((response) => {
                const check = response.header;
                // const data = response.body;
                if (check.error === "N") {
                  Message({
                    message: "บันทึกข้อมูลสำเร็จ",
                    type: "success",
                    duration: 2 * 1000,
                    onClose: () => {
                      this.goBack();
                    },
                  });
                }
              })
              .catch((error) => {
                Message({
                  message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
                  type: "error",
                  duration: 3 * 1000,
                });
                this.errorMsg = error;
                console.log(error);
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    checkCause() {
      if (this.formData.statusId == 2) {
        this.formData.cause = [];
      }
    },
    async beforeUpload(file) {
      console.log(file);
      const data = await toBase64(file);
      this.formData.images.push(data);
      // console.log(data);
      // console.log('images : ', this.formData.images);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async handleRemove(file, fileList) {
      console.log(fileList);
      if (this.formData.images.length > 0) {
        for (let i = 0; i < this.formData.images.length; i++) {
          if (this.formData.images[i] === (await toBase64(file.raw))) {
            this.formData.images.splice(i, 1);
            i--;
          }
        }
      } else if (Number(this.temp.attachments.length) > 0) {
        for (let index = 0; index < this.temp.attachments.length; index++) {
          if (this.temp.attachments[index].name === file.name) {
            this.formData.imagesDel.push(file.name);
          }
        }
      }
      // console.log('imagesDel : ', this.formData.imagesDel);
    },
    goBack() {
      this.$refs['detailForm'].resetFields();
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/step1" });
    },
  },
};
